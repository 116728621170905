import React from 'react'
import Layout from '../../components/layout'
import { recordings } from '../../data/poetry/recordings.yml'

export default () => (
  <Layout>
    <h1 className="mb-6 text-4xl">Recordings</h1>
    {recordings.map(({ caption, file }) => (
      <div className="mb-12" key={file}>
        <p className="text-xl my-22">{caption}</p>
        <audio controls src={`/audio/${file}`} className="w-full">
        <track kind="captions"></track>
          caption
        </audio>
      </div>
    ))}
  </Layout>
)
